
.login_title {
    text-align: center;
}
.login-btn {
    width: 100%;
    position: absolute !important;
    bottom: 0px;
    right: 0;
    left: 0;
    height: 80px;
   
}

.login-btn .MuiLoadingButton-loadingIndicator {
    color: white;
}

.content_login_form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}
.content_login_form .MuiTextField-root{
    max-width: 100%;
    margin: 10px auto !important;
}
.content_login_form  .MuiFormControlLabel-label {
    letter-spacing: -0.2px;
    font-size: 16px;
}
.otp_form{
    text-align: center;
    margin: auto;
    width: 100%;
}
.checkbox_label svg {
    width: 16px;
}
.checkbox_label{
    margin-right: 0 !important;
}
.box_login {
    padding: 32px;
    /* height: 80vh; */
    background-color: white;
}

.errorMsg {
    color: #d32f2f;
    text-align: right;
    width: 100%;
    font-size: 14px;
    padding-right: 25px;
    padding-bottom: 25px;
    font-weight: bold;
}

.restert {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    padding-bottom: 10px;
}

.input_tel input {
    direction: rtl;
}

@media (min-width: 760px) {
    .login_page .r_side:after {
       /*  content: ' ';
        border-top: 100vh solid #fff;
        border-right: 200px solid transparent;
        width: 0px;
        position: absolute;
        left: 0; */
    }

    .modal-body div {
        text-align: center;
    }
}

@media (max-width: 760px) {
    .login_page .r_side {
      /*  background-image: url('~assets/images/bg-login-mob.png');*/
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        background-position: top;
        background-size: contain;
        align-items: unset !important;
    }
}