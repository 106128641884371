.homeWrap {
    margin: 0 auto;
    text-align: center;
}

.appointment {
    text-align: right;
    padding: 0 20px 0px 20px;
    margin: 16px 16px 16px 0;
    box-shadow: none !important;
    border-radius: 0 !important;
    border-right: 2px solid grey;
    position: relative;
}


.appointment .app_data {
    width: 70%;
}

.appointment .app_icon {
    margin: auto;
}

.bold {
    font-weight: bold;
}

.note_warp {
    text-align: center;
    margin: 0px 13px;
}

.notes {
    text-align: right;
    margin: 0 16px;
}

.tab-list {
    width: 100%;
    position: sticky;
    top: 75px;
    background: #fff;
    z-index: 9;
}
.tab-list::after {
    content: '';
    width: 100vw;
    height: 1px;
    color: #e5e5e5;
    position: absolute;
    border-bottom: 1px solid;
    top: 45px;
    z-index: -1;
}
.title_head{
    margin-bottom: 30px;
}
hr.appointment_hr {
    margin: 25px 0;
}

.arrow_go {
    position: absolute;
    left: 0;
    top: calc(50% - 10px);
    width: 20px !important;
    color: #15497c;
}

.control-inputs {
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-items: center;
}

.control-inputs a {
    flex: 1;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.del_dialog .MuiPaper-root {
    border-radius: 16px;
    max-width: 350px;
}

#alert-del-dialog {
   min-width: 70% !important;
   text-align: center;
   min-height: 220px;
}

.close-btn {
    width: 100%;
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    height: 70px;     
    color: white !important;
}

.del-btn {
    text-align: center;
    text-decoration: underline;
}

.delTor{
    font-size: 20px;
    letter-spacing: -0.2px;
}