.active {
    background-color: #15497c !important;
    color: white;
}


.MuiDayCalendar-weekContainer{
    margin: 10px 0 !important;
    width: 100%;
    justify-content: space-between;
}

.MuiPickersDay-root:not(.Mui-disabled) {
    /* background-color:#ff5959; */
    background-image: url('../../../assets/images/circle.svg') ;
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding-top: 2px;
}

.MuiPickersDay-today {
    border: none !important;
}

.MuiPickersDay-root.Mui-selected{
    background-color: transparent !important;
    background-image: url('../../../assets/images/circle-red.svg') ;
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: center;
    display: flex;
    align-items: center;
    padding-top: 2px;
}

.MuiDateCalendar-root {
    width: 100% !important;
    margin: 0 !important;
    height: auto !important;
    max-height: inherit !important;
    direction: rtl;
}

.MuiDayCalendar-header {
    justify-content: space-between !important;
}

.MuiPickersCalendarHeader-root {
    padding: 0 12px 0 0;
    margin-bottom: 20px;
}

span.MuiTypography-root.MuiTypography-caption {
    flex: 1 1 0;
}

.time-warp.edit_tor{
    padding-bottom: 100px;
}

.close-btn.edit_tor.Mui-disabled{
    background-color: rgb(213 213 213) !important;
}

.drName {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}
.headDial h3{
    margin: 0;
        font-size: 20px;
        padding: 20px 0;
}
.headDial {
    position: sticky;
    top: 0;
    background: #fff !important;
    z-index: 9;
    text-align: center;
}

#alert-del-dialog.alert_edit_dial{
    padding: 0 24px 0 !important;
}

button.closeIcon{
    z-index: 99;
}