.footer {
    width: 100%;
    background-color: #313280;
}

.footer-warp {
    display: flex;
    flex-direction: row;
    padding:0 8%; 
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 84%;
    flex-flow: wrap;

}

.white-logo {
    height: 50px;
}

.desc {
    color: white;
    flex: 1;
    text-align: center;
}

.side-header-items {
    /* padding: 20px; */
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 26px 0 0 5px;
}