.step1 .MuiTextField-root {
    margin: 10px;
    width: 90%;
}

.step {
    margin: 0 auto;
    width: 90%;
    text-align: center;
}

.step .MuiDayCalendar-weekContainer{
    margin: 10px 0;
    width: 100%;
    justify-content: space-between;
}

.MuiDateCalendar-root {
    width: 100% !important;
    margin: 0 !important;
    height: auto !important;
    max-height: inherit !important;
}

.MuiDayCalendar-header {
    justify-content: space-between !important;
}
.MuiPickersDay-root {
    flex: 1 1 0 !important;
    /* padding: 10px; */
}
.MuiPickersCalendarHeader-root {
    padding: 0 12px 0 0;
    margin-bottom: 20px;
}



.next-btn {
    width: 100%;
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    left: 0;
    height: 70px;   
    border-radius: 0px !important;
    font-size: 20px !important;
}

.MuiCard-root{
    cursor: pointer;
}

.step2 .MuiCard-root {
    padding: 20px;
    margin: 15px;
    font-weight: bold;
    box-shadow: 0px 0px 5px 0px #15497c70 !important;
}

.step5 .MuiCard-root {
    padding: 20px;
    margin: 15px;box-shadow: 0px 0px 5px 0px rgb(85 85 85 / 29%) !important;
    border-radius: 10px;
}
.step5 .dep_title {
    font-size: 20px;
    font-weight: bold;
}
.step5 .flex_el {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

h3 {
    margin: 30px 0 20px;
    font-size: 20px;
}

h5 {
    font-size: 24px;
    margin: 20px auto 30px;
}


.step3 .MuiCard-root {
    padding: 16px;
    margin: 16px 0px;
    text-align: right;
    text-align: right;
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px #15497c70 !important;

}

.doc_title {
    font-size: 16px;
}

.doc_address {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.doc_address svg{
    width: 15px;
}

.time-warp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
    padding: 25px 0;
    border-top: 1px solid #e5e5e5;
}

.time-slot {
    padding: 5px 20px;
    margin: 3px;
    border-radius: 20px !important;
    cursor: pointer;
    box-shadow: 0 0 3px 0 #a1a1a1;
}

input {
    padding-right: 25px !important;
}

.back_btn{
    float: right;
    right: 20px;
    top: 0;
    svg{
        font-size: 20px;
    }
}

.search_med .MuiInputBase-root {
    border-radius: 16px !important;
}
.search_med .MuiInputBase-root input {
    padding: 10px 14px !important ;
}

hr {
    margin: 20px 0;
    border: none !important;
    border-top: .1px solid #e5e5e5 !important;
}

.validate_date{
    color: #00c5e0;
    letter-spacing: -0.5px;
}
.validate_date_failed{
    color: red;
    letter-spacing: -0.5px;
}