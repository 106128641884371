
.content_page{
    display: flex;
    flex-direction: column;
    max-width: 1000px !important;
    max-height: 100%;
    overflow-y: scroll;
}

.footer {
    flex-shrink: 0;
}