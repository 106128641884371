body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 450px !important;
  margin: 0 auto;
  color: #10204d;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  max-width: 450px;
  margin: auto !important;
}

input::placeholder {
  font-size: 16px;
}

@media (min-width:992px) {

  .phone_container {
    width: 360px;
    height: 780px;
    max-height: 90vh;
    border: 2px solid grey;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    background: #fff;
  }

  html {
    height: 100vh;
    background: #e5e5e5;
  }

  div#root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

}
button{
  direction: rtl;
}
a{
  cursor: pointer;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton.muirtl-1qweg3o-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}