
/* Sticky */
.is-sticky {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    min-height: 50px;
    height: 70px;
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.header {
    direction: rtl;
    display: flex;
    /* background-color:#fafafa !important; */
    /*  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); */
    align-items: center;
    justify-content: space-between;
    height: 75px;
}
.right_head{
    display: flex;
    align-items: center;
    padding:0 15px;
}
.logo {
    height: 50px;
    margin: 0 auto;
    cursor: pointer;
}
.k-i-menu {
    /* padding-top: 25px; */
    /* padding-right: 5px; */
    font-size: 25px !important;
    padding-left: 8px;
}
.avatar {
    padding: 15px;
    cursor: pointer;
   
}

.logout {
    cursor: pointer;
    text-decoration: underline;
    color: white;
    position: absolute;
    left: 30px;
}

.popup-content {
    direction: rtl;
    padding: 15px !important;
}

.logout {
    cursor: pointer;
    text-decoration: underline;
}
.page_title{
    padding: 0 15px;
    font-size: 20px;
    font-weight: 500;;
    position: absolute;
    right: 250px;
    width: 50vw;
}

.page_title a{
    padding-right: 20px;
    padding-left: 20px;
    color: #000000;
    text-decoration: none;
}

.page_title a:hover {
    text-decoration: none;
}

.side-header {
    height: 100%;
   
    position: absolute;
    left: 0;
  
    color: #fff;
    font-size: 20px;
}

/* .side-header:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 0 75px 35px;
} */

.side-header-items {
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.header-icon {
    height: 20px;
    padding-right: 10px;
}